import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Layout = React.lazy(() => import("./containers/Layout"));
const Projecoes = React.lazy(() => import("./pages/Projecoes"));
const Logs = React.lazy(() => import("./pages/Logs"));
const Login = React.lazy(() => import("./pages/Login"));
const Logout = React.lazy(() => import("./pages/Logout"));

const App = () => {
    const theme = createTheme({
        palette: {
            primary: { main: '#07467d' }
        },
        components: {
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            color: '#5bc0de',
                            fontWeight: 'bold',
                            textDecoration: 'none',
                        },
                    },
                },
            },
            MuiLink: {
              styleOverrides: {
                root: {
                  textDecoration: 'none',
                },
              },
            },
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route element={<Layout />}>
                        <Route index path="/projecoes" element={<React.Suspense fallback={<>...</>}><Projecoes /></React.Suspense>} />
                        <Route path="/logs" element={<React.Suspense fallback={<>...</>}><Logs /></React.Suspense>} />
                        <Route path="/logout" element={<React.Suspense fallback={<>...</>}><Logout /></React.Suspense>} />
                    </Route>
                </Routes>
            </BrowserRouter>
            
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              closeOnClick={true}
              pauseOnHover={true}
              draggable={true}
              theme="colored">
            </ToastContainer>
        </ThemeProvider>
    )
}

export default App;